import React from 'react'
import styled from 'styled-components'
// import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppAnalyticsHoverSideBarWapper = styled.div`
  max-width: 1920px;
  height: 90vh;
  margin: auto;
  /* background-color: #f5f5f5; */
  scroll-behavior: smooth;
  overflow: hidden;
  .PayableManagementHeading {
    margin: 0;
    text-align: center;
    padding: 100px 0px 100px 0px;
  }
  .tab {
    float: left;
    /* border: 1px solid #ccc; */
    /* background-color: #f1f1f1; */
    width: 40vw;
    height: auto;
    padding-left: 50px;

    @media (min-width: 1920px) {
      width: 35vw;
      padding-left: 124px;
    }
    @media (max-width: 440px) {
      /* padding-left: 4px; */
      padding: 0;
    }
  }

  /* Style the buttons inside the tab */
  .tab button {
    margin-bottom: 12px;
    display: block;
    background-color: inherit;
    color: #34a1d5;
    padding: 22px 16px 22px 90px;
    width: 60vh;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;
    transition: 0.5s;
    /* box-shadow: 8px 8px 10px 0 rgb(0 0 0 / 10%); */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media (max-width: 1090px) {
      font-size: 18px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
      padding-left: 10px;
      width: 30vh;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      padding-left: 4px;
      /* margin: 0; */
      width: 22vh;
    }
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);
    font-size: 25px;
    border-left: 5px solid #047cc2;
    @media (max-width: 440px) {
      font-size: 13px;
    }
    @media (max-width: 825px) {
      font-size: 13px;
    }
  }

  /* Create an active/current "tab button" class */
  .tab button.active {
    color: #047cc2;
    box-shadow: 2px 4px 10px 0 rgb(0 0 0 / 10%);

    border-left: 5px solid #047cc2;
  }

  /* Style the tab content */
  .tabcontent {
    float: left;
    padding: 0px 0px 0px 80px;
    /* border: 1px solid #ccc; */
    width: 60vw;
    border-left: none;
    height: 100vh;
    display: block;
    @media (max-width: 440px) {
      margin: 0;
      padding: 4px;
    }
    @media (min-width: 1920px) {
      width: 40vw;
      padding-left: 124px;
    }
  }

  /* Clear floats after the tab */
  .clearfix::after {
    content: '';
    clear: both;
    display: table;
  }
  .Sidebar-imageOne {
    width: 15vw;
    height: auto;
    display: block;
    margin-left: 160px;
    margin-right: auto;
    @media (max-width: 440px) {
      margin-left: 80px;
    }
    @media (min-width: 2560px) {
      width: 9vw;
      height: auto;
    }
    @media (min-width: 1920px) {
      width: 11vw;
      height: auto;
    }
  }
  .SideBar-points {
    margin: 20px 0px 0px 0px;
    font-size: 18px;
    @media (max-width: 1090px) {
      font-size: 16px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
    @media (max-width: 440px) {
      font-size: 12px;
      margin: 4px;
    }
  }
  .Sidebar-icons {
    width: 20px;
    height: auto;
    padding: 0px 5px 0px 0px;
  }
  .RightSidetabcontent {
    width: 100vw;
  }
  .tablinks {
    border-radius: 50px;
  }
`

export const AppAnalyticsHoverSideBar = () => {
  function openCity(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, cityName: string) {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName('tabcontent') as HTMLCollectionOf<HTMLElement>
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = document.getElementsByClassName('tablinks')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '')
    }
    const cityElement = document.getElementById(cityName)
    if (cityElement) {
      cityElement.style.display = 'block'
    }
    evt.currentTarget.className += ' active'
  }

  return (
    <AppAnalyticsHoverSideBarWapper>
      <ReUseHTwotag
        Heading="Real-time Analytics to Deliver Better Business Insights"
        HeadingStyle="PayableManagementHeading"
      />
      <div className="SideBarContainer">
        <div className="tab">
          <button className="butn tablinks" onMouseOver={(event) => openCity(event, 'SpendIntelligence')}>
            Spend Intelligence
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'ContractIntelligence')}>
            Contract Intelligence
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'SupplierIntelligence')}>
            Supplier Intelligence
          </button>
          <button className="tablinks" onMouseOver={(event) => openCity(event, 'InvoiceIntelligence')}>
            Invoice Intelligence
          </button>
        </div>
        <div className="RightSidetabcontent">
          <div id="SpendIntelligence" className="FirstSlide tabcontent">
            <img src="/img/AI_ML.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Comprehensive Spend Reporting (Cost Center, Supplier, and More)
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Consolidated View Across Multiple ERP/Source Systems
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Identify Categories for Strategic Sourcing Planning
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Multi-factor Spend Trending (Time, Supplier, and More)
            </p>
          </div>

          <div id="ContractIntelligence" className="tabcontent">
            <img src="/img/Contract_Icon.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Visibility into Upcoming Renewals
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Organize Contracts by Commodity Categorizations
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Be Prepared for Future Price Increases or Unplanned Changes
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Insight to All Contract Termination Dates
            </p>
          </div>

          <div id="SupplierIntelligence" className="tabcontent">
            <img src="/img/Supplier_icon.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              ESG and Diversity Tracking
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Financial Risk Tracking
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Reputational and Security Tracking
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Performance Tracking
            </p>
          </div>
          <div id="InvoiceIntelligence" className="tabcontent">
            <img src="/img/payable-realtime.png" className="Sidebar-imageOne" />
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Accounts Payable Performance Tracking
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Early Payment Discount Identification
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Eliminate Duplicate/Late/Fraudulent Payments
            </p>
            <p className="SideBar-points">
              <img src="/img/raindrop_icon.png" className="Sidebar-icons" />
              Payment Status Visibility
            </p>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
    </AppAnalyticsHoverSideBarWapper>
  )
}
